import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'

// create an axios instance
const service = axios.create({
    baseURL: '/v1', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['auth-terminal'] = 'user'
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation

            config.headers['auth-token'] = store.getters.token
        }
        config.headers['Content-Type'] = 'application/json; charset=utf-8'

        return config
    },
    error => {
        // do something with request error
        // console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data || {}
        // if the custom code is not 20000, it is judged as an error.
        // console.log(res.code)

        if (res.code !== 0) {
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // 1403 用户没有权限
            if (res.code !== 501 || res.code !== 502) {
                // console.log(res.message)
            } else {
                ElMessage({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        // ElMessage({
        //   message: error.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
)

export default service
