// import { login } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { register, getInfo, registerByLinkedIn } from '@/api/user'
const state = {
    token: getToken(),
    username: '',
    email: '',
    verify: 0,
    share: {},
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USERNAME: (state, username) => {
        state.username = username
    },
    SET_EMAIL: (state, email) => {
        state.email = email
    },
    SET_SHARE: (state, share) => {
        state.share = share
    },
    SET_VERIFY: (state, verify) => {
        state.verify = verify
    },
}

const actions = {
    setToken ({ commit }, token) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', token)
            resolve(token)
        })
    },
    register ({ commit }, userInfo) {
        // console.log('===', userInfo)
        return new Promise((resolve, reject) => {
            register(userInfo).then(res => {
                commit('SET_TOKEN', res.data.token)
                setToken(res.data.token)
                resolve(res.data.token)
            }).catch(e => {
                reject(e)
            })
        })
    },
    registerByLinkedIn ({ commit, dispatch }, code) {
        // console.log('===', code)
        return new Promise((resolve, reject) => {
            registerByLinkedIn({ code }).then(res => {
                commit('SET_TOKEN', res.data.token)
                setToken(res.data.token)
                dispatch('getInfo')
                resolve(res.data.token)
            }).catch(e => {
                reject(e)
            })
        })
    },

    // get user info
    getInfo ({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo()
                .then(res => {
                    commit('SET_USERNAME', res.data.username)
                    commit('SET_EMAIL', res.data.email)
                    commit('SET_VERIFY', res.data.is_verify)
                    sessionStorage.setItem("Client-Email", res.data.email)
                    sessionStorage.setItem("Is-Linkedin", res.data.is_linkedin)

                    resolve(res)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    share ({ commit }, share) {
        return new Promise(resolve => {
            commit('SET_SHARE', share)
            resolve()
        })
    },

    // user logout
    logout ({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '')
            removeToken()

            // reset visited views and cached views
            // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485

            resolve()
        })
    },

    // remove token
    resetToken ({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
