import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/404/notfound',
            name: 'notFound',
            component: () => import('@/views/common/404'),
        },
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/index/index'),
            meta: {
                keepalive: false
            }
        },
        {
            path: '/:params/:video',
            name: 'video',
            component: () => import('@/views/index/root'),
            meta: {
                keepalive: false
            }
        },
        {
            path: '/:params',
            name: 'project',
            component: () => import('@/views/index/root'),
            meta: {
                keepalive: false
            }
        },
        // {
        //   path: '/:project_id',
        //   name: 'project',
        //   component: () => import('@/views/index/project'),
        //   meta: {
        //     index: 1
        //   }
        // },
        // {
        //   path: '/:project_id/:video_id',
        //   name: 'video',
        //   component: () => import('@/views/index/video'),
        //   meta: {
        //     index: 1
        //   }
        // },
        {
            path: '/:catchAll(.*)',
            name: 'notFound',
            component: () => import('@/views/common/404'),
        }
    ]
})

export default router