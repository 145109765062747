
const TokenKey = 'Client-Token'
const EmailKey = 'Client-Email'

export function getToken () {
  return sessionStorage.getItem(TokenKey)
}

export function setToken (token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return sessionStorage.removeItem(TokenKey)
}

export function getEmail () {
  return sessionStorage.getItem(EmailKey)
}

export function setEmail (email) {
  return sessionStorage.setItem(EmailKey, email)
}

export function removeEmail () {
  return sessionStorage.removeItem(EmailKey)
}