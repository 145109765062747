import request from '@/utils/request'
/**
 * @param {object} data 
 */
export function register (data) {
  return request({
    url: '/user/register',
    method: 'POST',
    data
  })
}
/**
 * @param {object} data 
 */
export function registerByLinkedIn (data) {
  return request({
    url: '/user/register/linkedIn',
    method: 'POST',
    data
  })
}
/**
 * @param {object} data 
 */
export function getInfo () {
  return request({
    url: '/user/info',
    method: 'GET',
  })
}
/**
 * @param {object} data 
 */
export function getWatermask () {
  return request({
    url: '/user/watermask',
    method: 'GET',

  })
}
